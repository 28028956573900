<template>
  <div>
    <div class="container">
      <div class="row third">
        <div class="col-12">
          <p class="header mt-10">
            Мы заботимся о вас даже после прохождения нашего обучения и
            предлагаем
            <strong>
              выгодные условия страхования ответственности врачей</strong
            >
          </p>
        </div>
      </div>
    </div>
    <div class="third mt-5">
      <div class="grey-block not-round">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p class="subheader text-center">
                Мы часто сталкивались с ситуациями, когда врач объективно не
                виноват, но ему приходится нести материальную ответственность,
                ходить по судам.
              </p>
              <p class="text-center">
                <strong>Страховка поможет</strong> сократить потерю времени,
                нервов и денег
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container safety">
      <div class="row mt-5">
        <div class="col-12 col-md-6 d-flex flex-column align-items-md-center text-center text-md-start">
          <div>
            <div class="counter">
              01<br />
              <div class="hr d-none d-md-block"></div>
            </div>
            <div class="text mb-4 mt-3">
              Страховая компания предоставит юристов,<br />
              которые <strong>будут решать все вопросы за вас</strong>
            </div>
            <div class="d-none d-md-block"><img class="animate" src="/img/arrow.svg" /></div>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex flex-column align-items-md-center text-center text-md-start">
          <div>
            <div class="counter">
              02<br />
              <div class="hr d-none d-md-block"></div>
            </div>
            <div class="text mb-4 mt-3">
              Страховая компания
              <strong
                >компенсирует <br />
                моральный ущерб</strong
              >
            </div>
            <div class="d-none d-md-block"><img class="animate" src="/img/arrow.svg" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-5 mb-5 overflow-visible">
      <div class="row safety-2">
          <div class="col-12 col-md-6">
            <div class="subheader">
              <strong
                >Вы можете застраховать свою профессиональную медицинскую
                деятельность</strong
              >
              в компании Ингосстрах, воспользовавшись промокодом
            </div>
            <div class="header">Delmont 22</div>
            <div class="but">
              <button
                class="form-control"
                data-bs-toggle="modal"
                data-bs-target="#callback"
              >
                Узнать подробнее о страховании
              </button>
            </div>
          </div>
          <div class="col-12 col-md-6 position-relative d-none d-md-block">
            <img class="head img-fluid" src="/img/safetyman.png" />
          </div>

      </div>
    </div>
  </div>
</template>
