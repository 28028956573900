<template>
  <div class="container main-screen">
    <div class="row">
      <div class="col-md-4 col-12 align-items-center d-none d-md-block">
        <div class="row">
          <div class="col-12 mb-2">
            <span>129366, Россия, г.Москва, проспект Мира, 146 </span>
          </div>
          <div class="col-12 mb-2">
            <span class="text-secondary">info@delmont.pro</span>
          </div>
          <div class="col-12">
            <div class="grey-container d-flex align-items-center justify-content-center">
              <a href="https://api.whatsapp.com/send/?phone=89035685404&text&type=phone_number&app_absent=0" target="_blank"><img src="/img/whatsapp.png" class="img-fluid"></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-12">
        <div class="d-flex justify-content-center align-items-center">
        <div class="text-center d-flex flex-column">
          <div><img src="/img/logo.svg" alt="Placeholder" class="img-fluid mb-3"></div>
          <div class="mt-m3"><span class="text-secondary">Центр обучения гинекологов</span></div>
        </div>
        </div>
      </div>
      <div class="col-md-4 justify-content-end align-items-center">
        <div class="row">
          <div class="col-12 mb-2 text-center text-md-end mt-3 mt-md-0">
            <h3>+7 (915) 474-18-18</h3>
          </div>
          <div class="col-12 mb-2 text-end d-none d-md-block">
            <span class="text-secondary">Пн-Пт с 09:00 до 17:30<br>Сб-Вс - выходной</span>
          </div>
          <div class="col-12  text-center text-md-end">
            <h5 class="text-secondary" data-bs-toggle="modal" data-bs-target="#callback"><u>Заказать звонок</u></h5>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.main-screen {
  padding: 1rem 0;
}
</style>
