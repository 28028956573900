
<template>
    <div class="modal fade" id="doctor" tabindex="-1" role="dialog" aria-hidden="true" ref="modalElement">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h2>Дубинин Андрей Анатольевич</h2>
<h4>Врач акушер-гинеколог</h4>
<ul>
<li>Квалификационная категория: высшая</li>
<li>Кандидат медицинских наук</li>
<li>Стаж работы: 20 лет</li>
<li>Владение английским языком – свободное</li>
</ul>

<h4>Образование:</h4>
<ul>
<li>1991 - 1997 - обучение в Самарском государственном медицинском университете по специальности «Лечебное дело»</li>
<li>1998 - интернатура по специальности «Хирургия», ММУ №3, г. Самара, присвоена квалификация «Хирург»</li>
<li>1998-1999 - профессиональная переподготовка по специальности «Акушерство и гинекология»в Самарском государственном медицинском университете</li>
<li>2014 – первичная специализация «Онкология» в Самарском медицинском институте «Реавиз»</li>
<li>2014 - первичная специализация по «Эндоскопия» в Самарском медицинском институте «Реавиз»</li>
<li>2018 - первичная специализация по «Организации здравоохранения»</li>
<li>2019 - первичная специализация по «Ультразвуковой диагностике»</li>
</ul>

<h4>Опыт работы:</h4>
<h4>По основному месту:</h4>
<ul>
<li><strong> с 1998 по 2015 - врач акушер-гинеколог, с 2004 по 2014 - заведующий гинекологическим отделением ООО «Медицинская компания ИДК» г. Самара</strong></li>
<li>2014-2015 - ведущий специалист по акушерству и гинекологии ООО «Медицинская компания ИДК» г. Самара</li>
<li><strong> с 2015-2016 – заведующий гинекологическим отделением СПб ГБУЗ “Клиническая больница Святителя Луки”</strong></li>
<li>2016-2018 - врач акушер-гинеколог клиники “Мать и дитя Санкт-Петербург”</li>
<li>2018-2022 - главный врач клиники “Мать и дитя Санкт-Петербург”</li>
<li>с 2022 по настоящее время - заведующий гинекологическим отделением стационара «Мединцентр» ГлавУпДК при МИД России</li>
</ul>

<h4>По совместительству:</h4>
<ul>
<li>с 2009-по настоящее время-преподаватель “Центра высоких медицинских технологий” г. Казань – авторский курс «Гистероскопия», авторский курс на анимальных моделях «Осложнения лапароскопии»</li>
<li>с 2011 по 2014 – ассистент кафедры оперативной хирургии и клинической анатомии с курсом инновационных технологий Самарского государственного медицинского университета</li>
<li>2015-2016 – доцент кафедры акушерства, гинекологии и репродуктологии Первого Санкт-Петербургского государственного медицинского университета им. И.П. Павлова</li>
<li>2015-2018 – врач акушер-гинеколог отделения оперативной гинекологии Международного Центра Репродуктивной Медицины (г. Санкт-Петербург)</li>
<li>с 2017 по настоящее время – врач акушер- гинеколог отделения гинекологии ЧУЗ «Дорожная клиническая больница» (г. Санкт-Петербург)</li>
<li>с 2018 по настоящее время – врач акушер-гинеколог стационара клиники «Доктор Клин» (г. Клин)</li>
</ul>

<h4>Профессиональные компетенции:</h4>
<ul>
<li> Лапароскопическая гистерэктомия в том числе при больших размерах матки (до 24 недель)</li>
<li> Лапароскопическая тазовая лимфодиссекция при раке тела матки</li>
<li> Лапароскопическая миомэктомия с клиппированием маточных и внутренних подвздошных артерий</li>
<li> Лапароскопия при ретроцервикальном эндометриозе, кистах яичников</li>
<li> MESH сакровагинопексия при пролапсе гениталий</li>
<li> Лазерная лапароскопическая хирургия при эндометриозе и миоме</li>
<li> Лапароскопический циркляж – операции при истмико-цервикальной недостаточности, пластика рубца после кесарева сечения</li>
<li> Операции при стрессовом недержании мочи TVT-O, введение гидрогеля Bulkamid</li>
<li> Влагалищные пластические операции при опущении тазовых органов – трехуровневая гибридная реконструкция тазового дна</li>
<li> Гистероскопия: офисная диагностическая и оперативная, гистероскопический шейвер, гистерорезектоскопия (экспертный уровень)</li>
<li> Трансвагинальная гидролапароскопия</li>
<li>Амбулаторная гинекология: кольпоскопия, операции на шейке матки, трансвагинальное УЗИ</li>
</ul>

<h4>Дополнительное обучение, участие в конференциях, семинарах:</h4>
<ul>
<li>1999 - профессиональная переподготовка по специальности «Акушерство и гинекология», СамГМУ, присвоена квалификация «Врач акушер-гинеколог»</li>
<li>1999 - Международный конгресс по гинекологической эндоскопии, г. Москва</li>
<li>1999 - специализация по репродуктивной хирургии в гинекологии, Левен, Бельгия</li>
<li>2001 - конгресс Европейского общества гинекологов-эндоскопистов, Португалия</li>
<li>2002 - конгресс Всемирного общества гинекологов-эндоскопистов, Германия</li>
<li>2003 - конгресс Европейского общества гинекологов-эндоскопистов, Люксембург</li>
<li>2004 - стажировка по организации здравоохранения США, Вашингтон, США</li>
<li>2007 - конгресс Европейского общества гинекологов-эндоскопистов, Франция</li>
<li>2008 - конгресс Всемирного общества гинекологов-эндоскопистов, Италия</li>
<li>2009 - стажировка по эндоскопической хирургии в Центре высоких медицинских технологий Johnson&Johnson, г. Казань</li>
<li>2010 - конгресс Европейского общества гинекологов-эндоскопистов, Испания</li>
<li>2011 - Международный конгресс по репродуктивной медицине, г. Москва</li>
<li>2011 - стажировка по эндоскопической хирургии в клинике Клермон-Ферран, Франция</li>
<li>2011 - конгресс Всемирного Общества эндометриоза, г. Монпелье, Франция</li>
<li>2012 - конгресс Европейского общества гинекологов-эндоскопистов, г. Париж, Франция</li>
<li>2012 - конгресс Всемирной ассоциации акушеров-гинекологов, г. Рим, Италия</li>
<li>2012 - стажировка на рабочем месте в клинике профессора Ремера, г. Кельн, Германия</li>
<li>2013 - конгресс Европейского общества по контрацепции, г. Копенгаген, Дания</li>
<li>2013 - конгресс Европейского общества гинекологов-эндоскопистов, г. Берлин, Германия</li>
<li>2013 - сертификация по лапароскопии и гистероскопии в Европейской академии гинекологической эндоскопии (Бельгия), диплом гинеколога-эндоскописта</li>
<li>2014 - тренинговый курс "Современные тенденции гистероскопии" в госпитале SanGuiseppe, г. Милан, Италия</li>
<li>2014 - конгресс Американского колледжа акушеров-гинекологов, г.Чикаго, США</li>
<li>2014 - конгресс Европейского общества гинекологов –эндоскопистов, Брюссель, Бельгия</li>
<li>2014-интернатура по онкологии</li>
<li>2014-первичная специализации по эндоскопии</li>
<li>2014 - стажировка в клинике проф. Декампа, г. Анже, Франция</li>
<li>2014 - конгресс «Современные эндоскопические технологии в гинекологии», Санкт-Петербург</li>
<li><strong> 2015 - обучение в IRCAD на курсе хирургическая анатомия, г. Страсбург, Франция</strong></li>
<li><strong> 2015 - конгресс SEUD по эндометриозу и заболеваниям матки, г. Париж, Франция</strong> </li>
<li><strong> 2015 - стажировка по применению лазера в лапароскопической гинекологии, г. Милан, Италия</strong></li>
<li><strong> 2015 – стажировка по онкохирургии на кадаверах в анатомическом театре университета г. Тюбинген, Германия</strong></li>
<li>2015- конгресс Европейского общества гинекологов –эндоскопистов, Будапешт, Венгрия</li>
<li><strong>2016 - стажировка по хирургическому лечению опущения матки и влагалища в клинике г.Хекстер, Германия</strong></li>
<li>2016 - конгресс по эндометриозу и заболеваниям матки SEUD, Барселона, Испания</li>
<li>2016 – конгресс американской ассоциации гинекологов – лапароскопистов AAGL, Орландо, США</li>
<li>2017 – международный конгресс по гистероскопии, Барселона</li>
<li>2017 – стажировка по лазерной лапароскопии, госпиталь San Rafael, Милан, Италия</li>
<li>2017- стажировка в клинике Clermond-Ferrand, Франция</li>
<li>2017 - конгресс по эндометриозу, Неаполь, Италия</li>
<li>2018 – конгресс ENDODubai, ОАЭ</li>
<li><strong>с 2009 года - ежегодное проведение 4 тренингов по лапароскопии и гистероскопии на базе Казанского центра высоких медицинских технологий (в том числе тренинги на анимальных моделях – minipigs)</strong></li>
<li>2018 - конгресс гинекологов-эндоскопистов ESGE, Вена, Австрия</li>
<li>2018 - конгресс гинекологов-эндоскопистов AIONE, Бари Италия</li>
<li>2018 – обучение на рабочем месте в Университетской клинике СпбГУ- «гибридные операции в лечении тазового пролапса».</li>
<li>2019 – конгресс ENDODubai, ОАЭ</li>
<li>2019 - Тренинг на рабочем месте в госпитале Sacro Cuore, Negrar, Verona, Италия</li>
<li>2020 - конгресс ENDODubai, ОАЭ</li>
<li>Октябрь 2021 – конгресс гинекологов-эндоскопистов ESGE, Рим, Италия</li>
</ul>


<h4>Членство, награды, достижения:</h4>
<ul>
<li>Действительный член Американской ассоциации гинекологической лапароскопии (AAGL)</li>
<li>Первым в РФ выполнил бесконтактную офисную гистероскопию в 2005 году и первым в Санкт-Петербурге выполнил шейверную гистероскопию</li>
<li>Первым в РФ выполнил оперативную трансвагинальную лапароскопию</li>
<li>Одним из первых в РФ выполнил однопортовую гистерэктомию и аднексэктомию в РФ</li>
<li>Одним из первых в РФ начал выполнять лапароскопические операции СО-2 лазером в 2015 году</li>
<li>2007 - Почетная грамота Министерства здравоохранения и социального развития Самарской области</li>
<li>2008 - начал проведение лапароскопических курсов на анимальных моделях – мини pigs</li>
<li>2009 – начало проведение курсов по лапароскопии и гистеро копии в Центре Высоких медицинских технологий г. Казань – по настоящее время</li>
<li>2010 - рационализаторское предложение «Модифицированный способ удаления полости матки при операционной гистероскопии»</li>
<li>2010 - рационализаторское предложение «Способ поэтапного многократного бужирования цервикального канала при протяженном стенозе цервикального канала»</li>
<li>2011 – защита кандидатской диссертации «Клинико-морфологическая оценка эффективности различных методов оперативной гистероскопии при доброкачественной патологии полости матки»</li>
<li>2012 - Почетная грамота Министерства здравоохранения и социального развития Самарской области</li>
<li>2014 - Почетная грамота Министерства здравоохранения Самарской области</li>
<li>Автор и соавтор 33 печатных статей по тематике малоинвазивной гинекологии</li>
<li><strong>2014 - Сертификация по лапароскопии и гистероскопии в Европейской академии минимально инвазивной хирургии (MIS, Бельгия), диплом гинеколога-эндоскописта</strong> </li>
<li><strong>С 2018 - организатор первого в РФ курса «Лапароскопическая анатомия малого таза женщины» - совместно с компанией «Olympus» и «Основы лапароскопии в гинекологии», «техника эндоскопического шва»  совместно с «Karl Storz»- кадавер курсы. Проведено 16 курсов</strong></li>
<li>Впервые в г. Санкт-Петербург внедрил программы Fast-track и ERAS в практику лапароскопической хирургии</li>
<li>2020 – патент «Устройство для проведения учебных лапароскопических операций на кадавере»</li>
</ul>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { Modal } from "bootstrap";

  const modalElement = ref(null)

  const modal = ref()
  onMounted(() => {
    modal.value = new Modal(modalElement.value)
  })
  
  </script>