<template>
  <div class="container learn2">
    <div class="row third">
      <div class="col-12">
        <p class="header">
          Обучение организовано группой компаний «Киль» — <br />
          <strong>эксклюзивный поставщик Delmont</strong> в России
        </p>
      </div>
    </div>
    <div class="row mt-4 cards">
      <div class="col-12 col-md-6 d-flex justify-content-center">
        <img src="/img/dealer.jpg" class="img-fluid corners" />
      </div>
      <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
        <div>
          <p class="header">
            «Группа Медицинских компаний «Киль»<br />
            на рынке уже 25 лет
          </p>
          <p class="header">Среди наших клиентов:</p>
        </div>
        <div>
          <ul class="text">
            <li>
              Государственные организации и службы: Министерство
              Здравоохранения, Министерство Внутренних Дел, Федеральная Служба
              Безопасности, Федеральная Служба Охраны Российской Федерации и
              другие учреждения общегосударственного и регионального значения.
            </li>
            <li>
              Основные медицинские лечебные и научно-исследовательские заведения
              России, среди которых НИИ им. Склифосовского, Госпитали им.
              Бурденко, Институт болезней легких (Воеводина, Сербия) и другие.
            </li>
            <li>
              Международные гуманитарные организации и благотворительные фонды,
              такие как Комитет Красного Креста, «Врачи без границ», Детский
              Фонд ООН (ЮНИСЕФ).
            </li>
            <li>
              Компании-поставщики медицинских товаров в России, СНГ, странах
              Балтии, Восточной Европы и Ближней Азии.
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
        <p class="pink mt-4"><i> Компания активно участвует в государственной национальной программе по повышению уровня медпомощи «Здоровье», многих проектах по здравоохранению федерального и регионального масштаба</i></p>
    </div>
  </div>
</template>
