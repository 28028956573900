<template>
  <div class="bg-sec learn2">
    <div class="container">
      <div class="row text">
        <p class="header">
          Мы разработали
          <strong>несколько программ обучения</strong> амбулаторной
          гистероскопии для новичков и опытных специалистов
        </p>
        <p class="subheader">
          Для новичков это уверенный старт в профессии, а для профессионалов —
          возможность получить первыми знания о новых разработках европейских
          коллег-гинекологов
        </p>
      </div>
      <div class="row mt-4 cards">
        <div class="col-12 col-md-6">
          <img src="/img/learn1.png" class="img-fluid" />
        </div>
        <div class="col-12 col-md-6 d-flex flex-column justify-content-between text-start text-md-end">
          <div>
            <p class="header">Индивидуальное обучение на практике</p>
            <p class="text">
              Вы приходите в наш обучающий центр со своим пациентом, которому
              показана гистероскопия. Наш коуч-практикующий специалист с
              10-летним стажем проводит манипуляцию и параллельно объясняет вам,
              вы смотрите и учитесь.
            </p>
          </div>
          <div>
            <p class="header">Для гинеколога обучение бесплатное!</p>
            <p class="text">
              Платит только пациент за проведенную манипуляцию по прайсу
              клиники.
            </p>
            <p class="text">
              Пациенты охотно соглашаются на такой вариант, т.к. манипуляции
              производятся на современном оборудовании опытным специалистом или
              под его присмотром. Это гарантия безопасности и эффективности
              процедуры
            </p>
          </div>
          <div>
            <button
              class="form-control"
              data-bs-toggle="modal"
              data-bs-target="#callback"
            >
              Узнать подробнее об обучении
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-5 cards">
        <div class="col-12 col-md-6 d-flex flex-column justify-content-between">
          <div>
            <p class="header">
              2-3 дневный курс, который включает в себя теоретическую и
              практическую часть
            </p>
            <p class="text">
              3-дневный курс, который включает в себя теоретическую и
              практическую часть.  
            </p>
            <p class="text">Приобретенные на обучение практические навыки,
              вы легко примените в клинике.</p>
            <p class="text">Курс проводит <span>Дубинин Андрей
              Анатольевич <u data-bs-toggle="modal"
              data-bs-target="#doctor">подробнее о враче</u> </span></p>
            <p class="text">Малые группы — всего 6 человек.
              Максимум внимания и практики получает каждый.</p>
            <p class="text"><span>Получить полную
              программу мастер-класса</span> </p>
          </div>
          <div class="text-center">
            <img class="animate" src="/img/arrow.svg">
          </div>
          <div>
            <button
              class="form-control mb-2"
              data-bs-toggle="modal"
              data-bs-target="#callback"
            >
              Узнать подробнее об обучении
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 text-end">
          <img src="/img/learn2.png" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>
