
<template>
    <div class="modal fade" id="lisence" tabindex="-1" role="dialog" aria-hidden="true" ref="modalElement">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div id="carouselLisence" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="/img/lisence1.png" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="/img/lisence2.png" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="/img/lisence3.png" class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselLisence" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselLisence" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { Modal } from "bootstrap";

  const modalElement = ref(null)

  const modal = ref()
  onMounted(() => {
    modal.value = new Modal(modalElement.value)
  })
  
  </script>