<template>
    <div class="modal fade" id="callback" tabindex="-1" role="dialog" aria-labelledby="callbackModalLabel" aria-hidden="true" ref="modalElement">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h1 class="modal-title text-center" id="callbackModalLabel">Закажите звонок</h1>
            <p class="text-center subheader">и мы свяжемся с вами в течение 10 минут</p>
            <input class="form-control mb-4" type="text" placeholder="Введите имя" v-model="name">
            <input class="form-control mb-4" type="text" placeholder="Введите телефон" v-model="phone">
            <button class="form-control mb-2" @click="sendEmail()"> {{ result }} <div class="animate ink"></div></button>
            <p class="text-center politics">Нажимая на кнопку, вы даете согласие на обработку своих персональных данных.</p>
            
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { Modal } from "bootstrap";
  import emailjs from "emailjs-com";

  const modalElement = ref(null)

  const modal = ref()
  onMounted(() => {
    modal.value = new Modal(modalElement.value)
  })
  
  const name = ref("");
  const phone = ref("");
  const result = ref('Заказать звонок')

    const sendEmail = async () => {
      if (result.value === 'Заказать звонок') {
        try {
        const templateParams = {
          name: name.value,
          phone: phone.value,
        };
        result.value = 'Отправляем...'
        const res = await emailjs.send(
          "service_sw7rktj", // Замените на свой Service ID
          "template_028kmia", // Замените на свой Template ID
          templateParams,
          "wr1_iaXGfBfyurBLc" // Замените на свой User ID
        );
        if (res.status === 200) result.value = 'Сообщение отправлено!'
      } catch (error) {
        console.error("Ошибка отправки письма:", error);
        result.value = 'Ошибка, попробуйте позже'
      }
      }
      
    };

  </script>
  