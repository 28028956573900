<template>
  <div class="bg-sec learn2">
    <div class="container">
      <div class="row text">
        <p class="header">
          У нас уже есть <strong>3 обучающих центра в России</strong>, в которых
          вы можете обучиться по 1 и 2 программе
        </p>
        <p class="subheader">
          Если у вас есть вопросы или просто интерес — свяжитесь с ближайшим к
          вам центром, чтобы узнать подробности или выбрать удобное время и
          приехать лично в центр
        </p>
      </div>
      <div class="row third">
        <div
          class="col-12 col-md-4 card-group flex-column"
          v-for="(item, index) of images"
          :key="index"
        >
          <div class="card">
            <img :src="`/img/adr${index + 1}.png`" class="card-img-top" />
            <div class="card-body">
              <div class="card-text header">{{ item.header }}</div>
              <div
                class="card-text body"
                v-for="(li, index) of item.body"
                :key="index"
              >
                {{ li }}
              </div>
            </div>
            <div class="card-footer  text-end">
              <a :href="item.link" class="pink" target="_blank">Подробрее</a> 
            </div>
          </div>
          <button data-bs-toggle="modal" data-bs-target="#callback" class="form-control cbbtn mt-3 mb-3">Cвязаться с центром</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const images = ref([
  {
    header: "Санкт-Петербург",
    body: ["Адрес:", "Невский пр., 22-24"],
    link: "https://scanfert.clinic/",
  },
  {
    header: "Москва",
    body: [`Адрес:`, "Мичуринский проспект, д.7"],
    link: "https://scanfertclinic.ru/",
  },
  {
    header: "Ростов-на-Дону",
    body: [
      `Адрес:`,
      `ул. Советская, д. 51/2`,
      `пр-кт Космонавтов, д. 37`,
      `ул. 3-я Линия, д.3`,
      `ул. Еременко д. 25`,
    ],
    link: "https://junoclinic.ru/",
  },
]);
</script>
