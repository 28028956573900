<template>
  <div>
    <div
      class="learning mb-5"
      :style="{ 'background-image': 'url(/img/bg-photo.jpg)' }"
    >
      <div class="container">
        <div class="row callback">
          <div class="col-12">
            <p class="header">
              У вас есть <strong>возможность пройти обучение</strong> <br />
              на лучшем оборудовании в этой сфере уже сейчас
            </p>
            <p class="subheader mt-4">
              и стать одним из самых <strong>востребованных</strong>, ведущих
              <br />
              специалистов в области офисной гистероскопии
            </p>
            <div class="mt-5 row form">
              <div class="col-12 col-md-4 mb-2">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Введите ваше имя"
                  v-model="name"
                />
              </div>
              <div class="col-12 col-md-4 mb-2">
                <input type="text"  v-model="phone" class="form-control" placeholder="Телефон" />
              </div>
              <div class="col-12 col-md-4 mb-2">
                <button @click="sendEmail()" class="form-control">
                  {{ result }}
                </button>
              </div>
            </div>
            <p class="politics mt-2">
              Нажимая на кнопку, вы даете согласие на обработку своих
              персональных данных
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container learning">
      <div class="row text mb-5">
        <div class="col-12">
          <p class="header">
            Вы будете обучаться на инновационном французском<br />
            оборудовании Delmont,
          </p>

          <p class="subheader">
            которое разрабатывается вместе с врачами гинекологами
          </p>

          <div class="grey mt-5">
            <p class="subheader text-center">
              Каждая деталь продумана и доработана после тестирования на
              практике: инструмент приятно и <br />
              удобно держать в руках, ваши движения становятся более точными и
              безопасными
            </p>
            <p class="header text-center">
              Врачи, которые работали на другом оборудовании, отмечают <br />
              <strong> преимущества Delmont</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="row promo">
        <div class="col-12 col-md-4 d-flex flex-column justify-content-center">
          <div>
            <p class="header">Эффективнее</p>
            <p>
              Набор инструментов 7Fr., вместо традиционных 5Fr., позволяет
              выполнять больше манипуляций
            </p>
          </div>
          <div>
            <p class="header">Удобнее</p>
            <p>
              Для облегчения манипуляций каждый инструмент градуирован. Точные
              движения, быстрый результат без ошибок
            </p>
          </div>
          <div>
            <p class="header">Легче контролировать</p>
            <p>
              К стойке крепится iPad, в процессе манипуляций можно делать фото,
              видео и отправлять в вотсап, в почту, записывать на флешку,
              обсуждать между врачами
            </p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <img class="img-fluid" src="/img/gistero.png" />
        </div>
        <div class="col-12 col-md-4 d-flex flex-column justify-content-center">
          <div>
            <p class="header">Продуманнее</p>
            <p>
              Во время работы есть возможность снять тубус, тогда оптика
              становится тоньше, введение более комфортно для пациента
            </p>
          </div>
          <div>
            <p class="header">Безопаснее</p>
            <p>
              В комплекте гистеропомпа, которая помогает контролировать объем и
              давление воды, поступающей в полость матки
            </p>
          </div>
          <div>
            <p class="header">Совершеннее</p>
            <p>
              В комплект входит программа, с помощью которой составляется
              гистероскопический атлас, со снимками. Есть возможность отследить
              динамику.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
  import { ref} from 'vue'
  import emailjs from "emailjs-com";

const name = ref("");
  const phone = ref("");
  const result = ref('Узнать подробнее об обучении')

    const sendEmail = async () => {
      if (result.value === 'Узнать подробнее об обучении') {
        try {
        const templateParams = {
          name: name.value,
          phone: phone.value,
        };
        result.value = 'Отправляем...'
        const res = await emailjs.send(
          "service_sw7rktj", // Замените на свой Service ID
          "template_028kmia", // Замените на свой Template ID
          templateParams,
          "wr1_iaXGfBfyurBLc" // Замените на свой User ID
        );
        if (res.status === 200) result.value = 'Сообщение отправлено!'
      } catch (error) {
        console.error("Ошибка отправки письма:", error);
        result.value = 'Ошибка, попробуйте позже'
      }
      }
      
    };
    </script>