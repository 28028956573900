<template>
  <div class="grey pb-5">
    <div class="container third">
      <div class="row">
        <div class="col-12">
          <p class="header">
            <strong>Приходите в наши обучающие центры,</strong> растите <br />
            и развивайтесь вместе с нами
          </p>
        </div>
        <div class="col-12 col-md-6 position-relative">
          <div class="bg-sec dubinin">
            <div class="round-header text-center">
              <div class="position-relative">
               <i> Дубинин<br />Андрей<br />Анатольевич</i>
              </div>
            </div>
          </div>
          <img src="/img/dubinin.png" class="img-fluid" />
        </div>
        <div class="col-12 col-md-6">
          <div class="material">
            <div class="callback">
              <p class="head">
                Получить онлайн-консультацию, <br />
                подобрать удобный формат обучения
              </p>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Ваше имя"
                v-model="name"
              />
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Ваш телефон"
                v-model="phone"
              />
              <button
                class="form-control mb-3"
                @click="sendEmail()"
              >
                {{ result }}
              </button>
              <p class="subheader text-center">
                Нажимая на кнопку, вы даете согласие на обработку своих
                персональных данных
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref} from 'vue'
  import emailjs from "emailjs-com";

const name = ref("");
  const phone = ref("");
  const result = ref('Получить онлайн-консультацию')

    const sendEmail = async () => {
      if (result.value === 'Получить онлайн-консультацию') {
        try {
        const templateParams = {
          name: name.value,
          phone: phone.value,
        };
        result.value = 'Отправляем...'
        const res = await emailjs.send(
          "service_sw7rktj", // Замените на свой Service ID
          "template_028kmia", // Замените на свой Template ID
          templateParams,
          "wr1_iaXGfBfyurBLc" // Замените на свой User ID
        );
        if (res.status === 200) result.value = 'Сообщение отправлено!'
      } catch (error) {
        console.error("Ошибка отправки письма:", error);
        result.value = 'Ошибка, попробуйте позже'
      }
      }
      
    };
    </script>