<template>
  <div class="container learn2">
    <div class="row third">
      <div class="col-12">
        <p class="header">
          Во время и после обучения вы взаимодействуете с профессионалами,<br />
          у которых
          <strong> всегда можете проконсультироваться по любому вопросу</strong>
        </p>
      </div>
    </div>
    <div class="row mt-4 cards">
      <div class="col-12 col-md-4 d-flex flex-column justify-content-between">
        <div>
          <p class="text">
            <strong class="pink"> Вы первыми узнаете</strong> о доработках,
            обновлениях оборудования, т.к. мы работаем напрямую с французами —
            производителями Delmont
          </p>

          <p class="text">
            <strong class="pink">
              Вы раньше других врачей знакомитесь с современными европейскими
              трендами</strong
            >
            в гистероскопии — приглашаем на семинары французских коллег, которые
            охотно делятся своим опытом
          </p>

          <p class="text">
            <strong class="pink">
              У вас есть шанс ездить на международные конгрессы,
              семинары</strong
            >
            вне зависимости от вашего опыта. Такие поездки не только дают много
            опыта и новых знаний, но и очень вдохновляют наших учеников.
          </p>
        </div>
      </div>

      <div class="col-12 col-md-8 d-flex justify-content-center">
        
        <div class="d-md-flex d-none align-items-center" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
          <svg
            class="controls-1"
            width="94"
            height="94"
            viewBox="0 0 94 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="display: block"
          >
            <path
              d="M39 68L60 47L39 26"
              stroke="black"
              vector-effect="non-scaling-stroke"
              style="stroke-width: 1px; stroke: rgb(163, 79, 138)"
            ></path>
          </svg>
        </div>
        <div
          id="carouselExampleFade"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner" >
            <div v-for="(item, index) of 11" :key="index">
              <div class="carousel-item" :class="{ active: index === 0 }">
                <img
                  :src="`/img/slide${index + 1}.jpg`"
                  class="d-block w-100"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>

        <div class="d-md-flex d-none align-items-center" data-bs-target="#carouselExampleFade" data-bs-slide="next">
          <svg
            class="controls-2"
            width="94"
            height="94"
            viewBox="0 0 94 94"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style="display: block"
          >
            <path
              d="M39 68L60 47L39 26"
              stroke="black"
              vector-effect="non-scaling-stroke"
              style="stroke-width: 1px; stroke: rgb(163, 79, 138)"
            ></path>
          </svg>
        </div>
          
      
      </div>
    </div>
  </div>
</template>
