<template>
  <div>
    <div class="container mt-5 mb-5 overflow-visible">
      <div class="material overflow-visible">
        <div class="row position-relative">
            <img class="book" src="/img/book.png">
            <div class="col-12 col-md-7">
                <p class="header">А ещё мы приготовили для вас сюрприз:<br> <strong>набор эксклюзивных материалов</strong><br>
для гинекологов от европейских коллег,<br> которых нет в открытом доступе</p>
                <div class="subheader position-relative mb-3">Которые вы можете <strong>получить бесплатно</strong> <img class="gift" src="/img/gift.svg"> <img class="arrow d-none d-md-block" src="/img/gift-arrow.svg"></div>
            </div>
            <div class="col-12 col-md-5 callback">
                <input v-model="name" type="text" class="form-control mb-3" placeholder="Ваше имя">
                <input v-model="phone" type="text" class="form-control mb-3" placeholder="Ваш e-mail">
                <button class="form-control mb-3" @click="sendEmail"> {{ result }} </button>
                <div class="whatsapp d-flex justify-content-between align-items-center">Получить материалы по WhatsApp <div class="d-flex align-items-center justify-content-center"><img class="" src="/img/whatsapp.png"></div></div>
            </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
  import { ref} from 'vue'
  import emailjs from "emailjs-com";

const name = ref("");
  const phone = ref("");
  const result = ref('Получить набор материалов бесплатно')

    const sendEmail = async () => {
      if (result.value === 'Получить набор материалов бесплатно') {
        try {
        const templateParams = {
          name: name.value,
          phone: phone.value,
        };
        result.value = 'Отправляем...'
        const res = await emailjs.send(
          "service_sw7rktj", // Замените на свой Service ID
          "template_028kmia", // Замените на свой Template ID
          templateParams,
          "wr1_iaXGfBfyurBLc" // Замените на свой User ID
        );
        if (res.status === 200) result.value = 'Сообщение отправлено!'
      } catch (error) {
        console.error("Ошибка отправки письма:", error);
        result.value = 'Ошибка, попробуйте позже'
      }
      }
      
    };
    </script>