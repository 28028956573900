<template>
  <div class="container third">
    <div class="row">
      <div class="col-12">
        <p class="header">
          <strong> Амбулаторная гистероскопия</strong> — современный метод
          лечения,<br />
          за которым будущее
        </p>
        <div class="grey-block mt-5">
          <p class="text-center">В Европе 85% малоинвазивных гистероскопий</p>
          <p class="subheader text-center">
            и только 15% гистероскопий делается в операционной
          </p>
        </div>
        <p class="subheader mt-5">
          Амбулаторная гистероскопия на 100% комфортнее, эффективнее,<br />
          безопаснее для врача и пациента
        </p>
      </div>
    </div>
    <div class="row cards mt-5">
      <div
        class="col-12 col-md-4 card-group"
        v-for="(item, index) of images"
        :key="index"
      >
        <div class="card">
          <img :src="`/img/block-${index + 1}.jpg`" class="card-img-top" />
          <div class="card-body">
            <div class="card-text header">{{ item.header }}</div>
            <div
              class="card-text body"
              v-for="(li, index) of item.body"
              :key="index"
            >
              {{ li }}
            </div>
          </div>
          <div class="card-footer">
            <div class="count">0{{ index + 1 }}</div>
            <hr />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="grey-block mt-5 mb-5">
          <p class="text-center subheader">
            Каждый год растет количество показаний к офисной гистероскопии.
            <u
              >Кроме того, есть случаи, когда можно<br> проводить только
              амбулаторную гистероскопию.</u
            >
          </p>
          <p class="text-center">
            <strong>Это значит</strong>, что максимум через 5 лет каждый гинеколог БУДЕТ ОБЯЗАН<br>
            уметь проводить офисную гистероскопию.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const images = ref([
  {
    header: "Менее травматичная",
    body: [
      "• Операция производится через через вагиноскопический доступ;",
      "• Используется тонкий инструмент;",
      "• Небольшой размер оптики: исключаются травмы мягких тканей, органов и цервикального канала.",
    ],
  },
  {
    header: "Легко переносится",
    body: [
      `В 99% случаев анестезия не требуется. Сразу после манипуляции пациент может идти по своим делам, госпитализация не нужна.`,
    ],
  },
  {
    header: "Не требует серьезной подготовки",
    body: [
      `Доктору не нужно назначать множество обследований и анализов, т.к. практически нет противопоказанийи ограничений`,
    ],
  },
]);
</script>
