<template>
  <div class="bg-sec">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-7 d-flex flex-column justify-content-center">
          <h2 class="mb-4">
            Единственное в России
            <strong>обучение <br />амбулаторной гистероскопии</strong>
          </h2>
          <p class="subheader mb-5">
            на инновационном французском оборудовании Delmont,<br />на котором
            работают лучшие мировые врачи
          </p>
          <p class="mb-5">
            <strong>Повысьте проф. уровень за 3 дня</strong> и станьте ещё более
            востребованным <br />гинекологом, к которому пациенты идут с
            удовольствием, а лучшие<br />клиники приглашают на работу
          </p>
          <p class="mb-3">
            С возможностью <strong>бесплатного обучения</strong>
          </p>
          <div class="text-center col-8">
            <img class="animate" src="/img/arrow.svg" />
          </div>
          <button
            data-bs-toggle="modal"
            data-bs-target="#callback"
            class="btn col-8"
          >
            Узнать, как обучиться бесплатно
          </button>
        </div>
        <div class="col-12 col-md-5 position-relative">
            <div class="bg"><img src="/img/doctor-bg.png" alt=""></div>
          <div class="round-header text-center">
            <div class="position-relative">Обучили<br /><strong>более 150</strong> <br />врачей
            
            </div>
          </div>
          <div class="position-relative"><img class="img-fluid" src="/img/doctor.png" /></div>
          
        </div>
      </div>
    </div>
  </div>
</template>
