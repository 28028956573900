<template>
  <div class="grey rating">
    <div class="container learn2">
      <div class="row third mb-5">
        <p class="header">
          <strong>Посмотрите</strong> отзывы ваших коллег, которые уже обучились
          у нас и подняли свой профессиональный уровень
        </p>
      </div>

      <div class="row">
        <div
          id="rate"
          class="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div
              class="control-left"
              data-bs-target="#rate"
              data-bs-slide="prev"
            ></div>

            <div class="carousel-item active">
              <div class="row">
                <div class="col-12 col-md-4">
                  <img src="/img/rate2.jpg" class="d-block w-100" alt="..." />
                </div>
                <div class="col-12 col-md-8 text">
                  <p class="header pink">Дудина Анна Юрьевна</p>
                  <p class="subheader pink">
                    Врач акушер, гинеколог, кандидат медицинских наук , стаж 16
                    лет. Клиника "Юнона", Ростов-на-Дону
                  </p>
                  <p>
                    «Используем в клинике оборудование для внутриматочной
                    эндохирургии фирмы Delmont. Особенно впечатляет система
                    imaging icare, которая дает позволяет моментально
                    сформировать протокол операции, с возможностью добавления
                    меток на графическом изображении матки, а также на
                    фотоснимках. Метками можно отметить патологические изменения
                    в матке, а также указать на те или иные анатомические
                    ориентиры. Протокол операции отображает в себе максимальное
                    количество информации и содержит фото снимки, при этом его
                    формирование занимает всего несколько минут. Также протокол
                    операции можно отправить лечащему врачу, пациентке, через
                    любой мессенжер, это очень удобно и ценится коллегами из
                    других филиалов и клиник. Особым спросом в клинике, в
                    настоящее время, пользуется офисная гистероскопия- «one
                    touch». Это комфортно переносимый визуальный метод
                    исследования состояния полости матки, с возможностью
                    прицельной биопсии эндометрия. После того, как в нашей
                    клинике появился такой метод диагностики, пайпель биопсия
                    эндометрия практически не используется, как менее
                    информативный и болезненный метод. Рекомендую данное
                    оборудование к использованию".
                  </p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-12 col-md-4">
                  <img src="/img/rate1.jpg" class="d-block w-100" alt="..." />
                </div>
                <div class="col-12 col-md-8 text">
                  <p class="header pink">Ордиянц Елена Георгиевна</p>
                  <p class="subheader pink">
                    Гинеколог, стаж 14 лет, МЦ женское Здоровье Москва
                  </p>
                  <p>
                    Андрей Анатольевич, Юлия Сергеевна хочу поблагодарить вас за
                    тренинговый курс Up-to-date.
                  </p>

                  <p>
                    За Важный и нужный теоретический блог, постановку руки,
                    отработку практических навыков, демонстрацию работы в
                    операционной в режиме реального времени.
                  </p>

                  <p>
                    Спасибо, что щедро делитесь своим опытом, навыком владения
                    инструментами, лайфхаками помогающими сориентироваться
                    начинающему в этой сфере врачу и даете надежду, что все
                    возможно.
                  </p>

                  <p>
                    Спасибо за отдачу и желание помочь в освоении материала и
                    внедрении в практическую деятельность.
                  </p>

                  <p>
                    Я получила поддержку и уверенность, что двигаясь маленькими
                    шагами от простого к сложному смогу вновь освоить данную
                    методику.
                  </p>

                  <p>
                    Благодарю. <br />
                    Всего вам наилучшего!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#rate"
            data-bs-slide="prev"
          >
            <div class="control-wrapper d-flex align-items-center">
              <div class="carousel-control-prev-icon" aria-hidden="true"></div>
            </div>
            <span class="visually-hidden">Previous</span>
          </button>

          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#rate"
            data-bs-slide="next"
          >
            <div class="control-wrapper d-flex align-items-center">
              <div class="carousel-control-next-icon" aria-hidden="true"></div>
            </div>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
