<template>
  <div class="footer">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 text-center">
          <img src="/img/logo2.png" />
        </div>
        <div class="col-12 col-md-4 text-center">
          © «МедТех.Москва», 2009-2023. Все права защищены.

          <div class="row justify-content-center mt-3">
            <div class="col-3">
              <img data-bs-toggle="modal" data-bs-target="#lisence"  src="/img/lisence1.png" class="img-fluid" />
            </div>
            <div class="col-3">
              <img data-bs-toggle="modal" data-bs-target="#lisence" src="/img/lisence2.png" class="img-fluid" />
            </div>
            <div data-bs-toggle="modal" data-bs-target="#lisence" class="col-3">
              <img src="/img/lisence3.png" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4 text-md-end text-center">
          <a href="tel:+79035685404">+7 (915) 474-18-18 </a> <br />
          <p data-bs-toggle="modal" data-bs-target="#callback" class="callback">
            <u>Заказать звонок</u>
          </p>
        </div>
      </div>
    </div>
    
</div>
</template>
